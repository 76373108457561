<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Jewellery</h4>
            </div>

            <div class="auth-page-right-content">
              <div class="heading">
                <h6>Forgot Password</h6>
                <p
                  v-if="errorMessage != null"
                  class="tag-line"
                  style="color: red"
                >
                  {{ errorMessage }}
                </p>
                <p v-else>
                  Provide your account’s email you want to reset your password
                </p>
              </div>
              <form @submit.prevent="forgot(0)">
                <div class="form-group">
                  <i class="far fa-envelope"></i>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email Address"
                    autocomplete="off"
                    v-model.trim="$v.details.email.$model"
                    v-on:keyup="errorNull"
                    :class="{
                      'is-invalid': $v.details.email.$error,
                      'is-valid': !$v.details.email.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.email.required"
                      >Email is required</span
                    >
                    <span v-if="!$v.details.email.email"
                      >Email must be valid
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  class="auth-btn"
                  @click="forgot(0)"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Next</span>
                </button>
              </form>
              <span class="account">
                Remember Password ?
                <router-link to="/login">Login</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import AuthLeft from "../../../components/AuthLeft/AuthLeft.vue";

export default {
  name: "Forgot",

  components: { AuthLeft },

  data: function() {
    return {
      errorMessage: null,
      isLoadingArray: [],
      details: {
        email: "",
      },
    };
  },
  validations: {
    details: {
      email: {
        required,
        email,
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendResetPasswordRequest"]),

    forgot: function(index) {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.sendResetPasswordRequest(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.$router.push({ name: "VerifyEmail" });
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },

    errorNull() {
      this.errorMessage = null;
    },
  },
};
</script>
