<template>
  <div class="auth-page-left">
    <div class="logo">
      <img src="../../assets/images/logo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLeft",
};
</script>